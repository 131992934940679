*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --black: black;
  --white: #f3f7fc;
  --cool-blue: #849dc5;
  --gray: #f3f7fca5;
  --yellow: #ffb423;
  --green: #84ff23;
  --red: #e93800;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  background-color: var(--black);
  color: var(--white);
}

a {
  color: currentColor;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

body,
html,
h1,
h2,
h3,
p {
  margin: 0;
}

main {
  text-align: center;
  height: 100vh;
  position: relative;
  display: flex;
  overflow: hidden;
}

/* UI */
.button {
  background: none;
  text-transform: uppercase;
  border: 1px solid var(--cool-blue);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: var(--white);
  box-shadow: 0px 0px 8px var(--cool-blue);
  border-radius: 7px;
  padding: 10px 32px;
  transition: box-shadow 350ms ease-out, text-shadow 300ms ease-out;
  backdrop-filter: blur(10px);
}

.button:hover {
  box-shadow: 0px 0px 12px var(--cool-blue);
  text-shadow: 0px 0px 12px var(--cool-blue);
  cursor: pointer;
}

.disclaimer {
  margin: 1rem auto 0;
  font-size: 10px;
  opacity: 0.8;
  max-width: 280px;
}

/* Background */
.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.background > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: blur(0px);
  transition: filter 350ms ease-in-out;
}

.background--blurred > video {
  filter: blur(10px);
}

.background > video[poster] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Not supported in Firefox */
@supports (backdrop-filter: blur(0)) {
  .background > video,
  .background--blurred > video {
    filter: none;
  }

  .background > .blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    pointer-events: none;
    opacity: 0;
    transition: opacity 350ms ease-out;
  }

  .background--blurred > .blur {
    opacity: 1;
  }
}

/* KYC */
.kyc {
  display: flex;
  flex-direction: row;
  grid-column-gap: 80px;
  padding: 20px;
  flex-wrap: wrap;
  grid-row-gap: 48px;
  align-items: flex-end;
  overflow: hidden;
  margin: auto;
}

.kyc-block {
  text-align: left;
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  min-width: 150px;
}

.kyc-label {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--gray);
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  font-weight: bold;
}

.kyc-body {
  font-size: 18px;
  overflow: hidden;
  max-width: calc(100vw - 40px);
  text-overflow: ellipsis;
}

.status {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.status--not_found {
  box-shadow: inset 0 0 0 1px var(--gray);
}

.status--in_review {
  background: var(--yellow);
}

.status--approved {
  background: var(--green);
}

.status--declined {
  background: var(--red);
}

/* Sponsors List */
.sponsors {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 2;
  flex-direction: row;
  width: 100%;
  padding: 40px 0;
}

.sponsors-list {
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 24px;
}

.sponsors-list::before,
.sponsors-list::after {
  content: "";
  height: 1px;
  background: #d9d9d94a;
  width: 100%;
  flex: 1;
}

/* Mobile overrides */
@media (max-width: 768px) {

  .sponsors-list {
    gap: 10%;
  }

  .sponsors-list::before,
  .sponsors-list::after {
    display: none;
  }

  .kyc {
    flex-direction: column;
    align-items: center;
  }

  .kyc-label {
    justify-content: center;
  }

  .kyc-body {
    text-align: center;
  }
}
